import { template as template_c0e5005a03e446728bd89dce009d2210 } from "@ember/template-compiler";
const WelcomeHeader = template_c0e5005a03e446728bd89dce009d2210(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
