import { template as template_5370613327dd4ce080448476f42da4f0 } from "@ember/template-compiler";
const FKText = template_5370613327dd4ce080448476f42da4f0(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
