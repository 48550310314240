import { template as template_d77cff23dde342ac980a045cae62e676 } from "@ember/template-compiler";
const FKControlMenuContainer = template_d77cff23dde342ac980a045cae62e676(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
