import { template as template_d11ca1183ea34da1be63691afd9ca4ed } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
import i18n from "discourse-common/helpers/i18n";
import getURL from "discourse-common/lib/get-url";
const BackToForum = template_d11ca1183ea34da1be63691afd9ca4ed(`
  <a href={{getURL "/"}} class="sidebar-sections__back-to-forum">
    {{icon "arrow-left"}}

    <span>{{i18n "sidebar.back_to_forum"}}</span>
  </a>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackToForum;
